<div class="w-full on-screen flex justify-center h-full">
  <div class="w-full xl:max-w-[1122px] gap-4 p-6 grid grid-rows-[min-content_1fr_min-content]">
    <div class="flex gap-2 justify-between">
      <h4 class="hidden md:block">Mục tiêu của bạn: {{ learningGoal.name }}</h4>
      <div class="h8 md:hidden">Mục tiêu của bạn: {{ learningGoal.name }}</div>
      <strong class="text-lightBlue-1 w-[45px] flex justify-between items-center">
        <span>{{ Math.floor(counter / (60 * 1000)) }}:</span>
        <span>{{
          counterTime.getSeconds() < 10 ? '0' + counterTime.getSeconds() : counterTime.getSeconds()
        }}</span></strong
      >
    </div>
    <!-- <kyonsvn-questions-progress class="w-full" [progress]="testProgress"></kyonsvn-questions-progress> -->
    <div #testContentElm class="col rounded-lg bg-white p-4 overflow-y-auto md:overflow-hidden md:flex-1">
      <div class="flex w-full justify-end gap-2">
        <button class="btn-icon primary outlined" (click)="testComplete()"><i class="icon-Submit"></i></button>
        <a class="btn-icon primary outlined" href="https://forms.gle/b36mTdMWNRY1qkZh7" target="_blank"
          ><i class="icon-Error"></i
        ></a>
      </div>
      <kyonsvn-test-content
        [content]="testContent"
        (progressEvent)="updateProgress($event)"
        [submission]="testSubmission"
        (submissionEvent)="updateSubmission($event)"
        [currentIndex]="currentTestIndex"
        (currentIndexEvent)="goTo($event)"
        [isActive]="true"
        class="flex md:flex-1 md:max-h-full md:overflow-y-auto"
      ></kyonsvn-test-content>
      <div class="justify-between hidden md:flex flex-col gap-3">
        <div class="flex flex-1 gap-2">
          <strong class="uppercase">Phím tắt:</strong>
          <span>Bấm 1,2,3,4 chọn đáp án, bấm space sẽ làm tiếp hoặc nộp bài</span>
        </div>
        <div class="flex gap-4 items-start w-full overflow-hidden">
          <button (click)="scrollLeft()" [disabled]="currentTestIndex === 0" class="btn primary">Câu trước</button>
          <div class="flex-1 flex gap-1 w-full overflow-hidden">
            <button class="btn-icon primary outlined" (click)="previousPage()"><i class="icon-ChevronLeft"></i></button>
            <div #scrollElm class="w-[calc(100%_-_92px)] overflow-x-auto flex gap-1 hide-scrollbar">
              <a
                class="btn-icon outlined"
                *ngFor="let question of testContent.questions; index as i"
                [ngClass]="{
                  'fill-blue': i === currentTestIndex,
                  'outline-blue': i !== currentTestIndex,
                  'fill-black': testSubmission.hasAnswer(question.id),
                  'orange': i!==currentTestIndex,
                }"
                (click)="currentTestIndex = i"
              >
                {{ i + 1 }}
              </a>
            </div>
            <button class="btn-icon outlined orange" (click)="nextPage()"><i class="icon-ChevronRight"></i></button>
          </div>
          <button
            *ngIf="currentTestIndex !== testContent.questions.length - 1"
            (click)="scrollRight()"
            class="btn primary"
          >
            Câu kế tiếp
          </button>
          <button
            *ngIf="currentTestIndex === testContent.questions.length - 1"
            id="continue"
            (click)="testComplete()"
            class="btn primary"
          >
            Nộp bài
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="ads-md">
      <span class="text-white">Muốn luyện tập với vô hạn câu hỏi được tạo ra bởi Trí tuệ nhân tạo (AI)?</span>
      <button class="btn btn-large" (click)="showWaitlistPopup = true">Đăng ký Danh sách chờ của Kyons!</button>
    </div> -->
    <div class="md:hidden w-full flex items-center justify-between gap-2 overflow-hidden">
      <div class="flex flex-1 gap-2 items-center w-full overflow-hidden">
        <button (click)="scrollLeft()" [disabled]="currentTestIndex === 0" class="btn-icon primary">
          <i class="icon-ChevronLeft"></i>
        </button>
        <!-- <a
          class="btn-icon primary outlined order-1 md:order-1"
          href="https://forms.gle/b36mTdMWNRY1qkZh7"
          target="_blank"
          #tooltip="matTooltip"
          matTooltip="Nội dung “có vấn đề”? Thông báo cho Kyons liền tay!"
          matTooltipPosition="above"
          matTooltipHideDelay="5000"
          matTooltipClass="above"
          aria-label="Button that displays a tooltip that hides when scrolled out of the container"
          ><i class="icon-Error"></i
        ></a> -->
        <div #scrollXsElm class="max-w-[calc(100%_-_92px)] overflow-x-auto flex gap-[4px] hide-scrollbar">
          <button
            class="btn-icon outlined"
            *ngFor="let question of testContent.questions; index as i"
            [ngClass]="{
              'fill-blue': i === currentTestIndex,
              'outline-blue': i !== currentTestIndex,
              'fill-black': testSubmission.hasAnswer(question.id),
              'orange': i!==currentTestIndex,
            }"
            (click)="currentTestIndex = i"
          >
            {{ i + 1 }}
          </button>
        </div>
        <button
          *ngIf="currentTestIndex === testContent.questions.length - 1"
          (click)="testComplete()"
          class="btn-icon primary"
        >
          <i class="icon-Submit"></i>
        </button>
        <button
          *ngIf="currentTestIndex !== testContent.questions.length - 1"
          (click)="scrollRight()"
          [disabled]="currentTestIndex === testContent.questions.length - 1"
          class="btn-icon primary"
        >
          <i class="icon-ChevronRight"></i>
        </button>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="showHavingTime"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[700px] items-center">
    <img src="/assets/images/testsubmit.webp" alt="" />
    <div class="col w-full gap-6">
      <h5>Vẫn còn thời gian kiểm tra, bạn muốn nộp bài luôn?</h5>
      <span
        >Hãy xem bài kiểm tra thử này giống như kỳ thi thiệt mà làm bài thật nghiêm túc và tránh bị sai nhảm nha bạn ơi.
        Càng cẩn thận, lộ trình học của Kyons tạo ra sẽ càng phù hợp với khả năng của bạn đó!</span
      >
      <button (click)="ignoreHavingTime = true; submitHavingTime()" class="btn primary w-full">Nộp bài luôn</button>
      <button (click)="showHavingTime = false; ignoreIncomplete = false" class="btn primary outlined w-full">
        Cho mình kiểm tra lại
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="showIncomplete"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[700px] items-center">
    <img src="/assets/images/warning.webp" alt="" />
    <div class="col w-full gap-6">
      <h5>Bạn vừa bỏ sót vài câu kìa!</h5>
      <span>
        Bạn đã bỏ sót vài câu kìa! Nếu bạn vẫn chọn nộp bài thì những câu đó sẽ tính là sai và ảnh hưởng đến lộ trình
        học tập của bạn đó.
      </span>
      <p>Chắc chắn muốn nộp bài chứ?</p>
      <button (click)="showIncomplete = false; ignoreHavingTime = false" class="btn primary w-full">
        Để mình làm tiếp
      </button>
      <button (click)="ignoreIncomplete = true; submitIncomplete()" class="btn primary outlined w-full">
        Nộp bài luôn
      </button>
    </div>
  </div>
</div>
@if(hasError === 'SubmittedMockTest'){
<div class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center">
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[500px] items-center">
    <div class="col w-full gap-6">
      <h5 class="w-full text-center">Bài kiểm tra đã hoàn thành</h5>
      <p></p>
      <button (click)="goToResult()" class="btn primary w-full">Xem kết quả</button>
      <button (click)="goToReview()" class="btn primary w-full">Xem đáp án</button>
    </div>
  </div>
</div>
} @else if(hasError !== ''){
<div class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center">
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[500px] items-center">
    <div class="col w-full gap-6">
      <h5 class="w-full text-center">{{ hasError }}</h5>
      <p></p>
      <button (click)="hasError = ''" class="btn primary w-full">Ok</button>
    </div>
  </div>
</div>
}
