import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { Title } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { NavigationService } from '@data/navigation/navigation.service';
import { UserService } from '@data/user/user.service';
import { LangKey, Workspace } from '@data/workspace/workspace-models';
import { WorkspaceService } from '@data/workspace/workspace.service';
import { firstValueFrom } from 'rxjs';
import { WorkspaceEditComponent } from './workspace-edit/workspace-edit.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, MatMenuModule, WorkspaceEditComponent],
  templateUrl: './workspace.component.html',
  styleUrl: './workspace.component.scss'
})
export class WorkspaceComponent implements OnInit {
  paths = inject(NavigationService).paths;
  workspaceService = inject(WorkspaceService);
  userId = inject(UserService).currentUser().uuid;
  route = inject(Router);
  titleService = inject(Title);

  creating = false;
  searchChat = '';
  searchFocus = false;
  workspaceGoal = '';
  workspaceName = '';
  workspaces: Workspace[] = [];
  language: LangKey = this.workspaceService.getWorkspaceLanguage();
  LangKey = LangKey;
  isInitialized = false;
  isInitializing = false;
  filteredWorkspaces: Workspace[] = [];
  error = '';
  selectedWorkspace: Workspace = Workspace.empty();
  hover: { [key: string]: boolean } = {};

  @ViewChild('errorDialog') errorDialogElm!: ElementRef<HTMLDialogElement>;
  @ViewChild('dialog') dialogElm!: ElementRef<HTMLDialogElement>;
  @ViewChild('editDialog') editDialogElm!: ElementRef<HTMLDialogElement>;

  ngOnInit(): void {
    this.titleService.setTitle('KYONS AI - Không gian làm việc');
    firstValueFrom(this.workspaceService.getWorkspaces(this.userId)).then((workspaces) => {
      this.workspaces = workspaces;
      this.filteredWorkspaces = workspaces;
    });
  }

  onSearchChat() {
    this.filteredWorkspaces = this.workspaces.filter(w => w.searchIndex.toLowerCase().includes(this.searchChat.toLowerCase()));
  }
  onFocus() {
    this.searchFocus = true;
  }
  previousPage(scrollElm: HTMLElement) {
    scrollElm.scrollLeft -= 180;
  }
  nextPage(scrollElm: HTMLElement) {
    scrollElm.scrollLeft += 180;
  }

  createWorkspace() {
    this.creating = true;
    firstValueFrom(this.workspaceService.createWorkspace(this.userId, this.workspaceGoal, this.workspaceName, this.language)).then((workspaceId) => {
      this.dialogElm.nativeElement.close();
      this.creating = false;
      this.route.navigate([this.paths.workspaceDetails.path.replace(':id', workspaceId)]);
    }, error => {
      console.error(error);
      this.error = 'Có lỗi xảy ra, vui lòng thử lại sau';
      if (error.code == "InvalidParams") {
        this.error = 'Vui lòng điền đầy đủ thông tin';
      }
      this.errorDialogElm.nativeElement.showModal();
      this.creating = false;
    });
  }

  updateLanguage($event: Event) {
    this.workspaceService.setWorkspaceLanguage((<HTMLSelectElement>$event.target).value as LangKey);
  }

  summarizeGoal() {
    this.isInitialized = true;
    this.isInitializing = true;
    firstValueFrom(this.workspaceService.summarizeGoal(this.userId, this.workspaceGoal)).then(({ summary, language }) => {
      this.workspaceName = summary;
      this.language = language;
      this.isInitializing = false;
      this.dialogElm.nativeElement.showModal();
    }, error => {
      console.error(error);
      this.error = error;
      this.errorDialogElm.nativeElement.showModal();
      this.isInitializing = false;
    })
  }

  editWorkspace(workspace: Workspace) {
    this.selectedWorkspace = workspace;
    this.editDialogElm.nativeElement.showModal();
  }

  onDeleted(success: boolean) {
    this.editDialogElm.nativeElement.close();
    if (success) {
      this.workspaces.splice(this.workspaces.indexOf(this.selectedWorkspace), 1);
    }
    else {
      this.error = 'Failed to delete workspace.';
      this.errorDialogElm.nativeElement.showModal();
    }
  }

  onEdited(newData: any) {
    if (newData && newData.id) this.workspaces.find((workspace) => workspace.id === newData.id)!.update(newData);
    this.editDialogElm.nativeElement.close();
  }

  onMouseHover($event: Event, workspaceId: string) {
    this.hover[workspaceId] = $event.type === 'mouseover';
  }

}
