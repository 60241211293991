<div class="flex flex-col items-start justify-start w-full md:h-full">
  <div class="tab-wrapper primary">
    <div class="tab-item" [ngClass]="{ active: activeTab() === 0 }" (click)="activeTab.set(0)">
      <i class="icon-Work"></i> Thông tin cơ bản
    </div>
    <div class="tab-item" [ngClass]="{ active: activeTab() === 1 }" (click)="activeTab.set(1)">
      <i class="icon-Subscription"></i> Thông tin nâng cao
    </div>
    <!-- <div class="tab-item" [ngClass]="{ 'on-screen text-primaryBlue': activeTab === 2 }" (click)="activeTab = 2">
      <i class="icon-Shop text-xl"></i> Cửa hàng
    </div> -->
    <!-- <div class="tab-item" [ngClass]="{ active: activeTab() === 3 }" (click)="activeTab.set(3); loadOrderHistory()">
      <i class="icon-History"></i> Lịch sử
    </div> -->
  </div>
  <div class="w-full md:p-0 p-4 md:h-full overflow-auto flex flex-col">
    <div class="md:on-screen md:rounded-tl-none rounded-lg overflow-y-auto p-0 md:p-6 h-full">
      @switch(activeTab()){ @case(0){
      <div class="flex flex-col gap-4 on-screen md:bg-transparent p-4 md:p-0 rounded-lg md:h-full overflow-auto">
        <h5>Thông tin cơ bản</h5>
        <form class="w-full col gap-4" [formGroup]="profileForm">
          <div class="flex gap-4 flex-col md:flex-row">
            <div class="flex flex-col gap-1 flex-1">
              <span>Họ của bạn</span>
              <input type="text" formControlName="lastName" placeholder="VD: Nguyễn Văn" />
            </div>
            <div class="flex flex-col gap-1 flex-1">
              <span>Tên của bạn</span>
              <input type="text" formControlName="firstName" placeholder="VD: An" />
            </div>
          </div>
          <div class="flex flex-col gap-1 flex-1">
            <span>Email</span>
            <input type="text" formControlName="email" readonly placeholder="VD: nva@gmail.com" />
          </div>
          <hr />
        </form>
        <button
          class="btn primary small self-end"
          [ngClass]="{ shine: processing }"
          (click)="updateProfile(profileForm)"
          [disabled]="processing || isDone"
        >
          @if(isDone){
          <i class="icon-Check"></i>
          }
          {{ isDone ? ' Đã cập nhật' : processing ? 'Đang xử lý' : 'Cập nhật' }}
        </button>
      </div>
      } @case(1){
      <div class="flex flex-col gap-4 on-screen md:bg-transparent p-4 md:p-0 rounded-lg md:h-full overflow-auto">
        <h5>Thông tin riêng tư</h5>
        <form class="w-full col gap-4" [formGroup]="extraForm">
          <!-- <div class="flex gap-4">
            <div class="flex flex-col gap-1 flex-1">
              <span>Trường của bạn</span>
              <input type="text" formControlName="school" placeholder="VD: THPT Nguyễn Thượng Hiền" />
            </div>
            <div class="flex flex-col gap-1 flex-1">
              <span>Khối lớp</span>
              <select formControlName="grade">
                <option *ngFor="let item of ['Khối 10', 'Khối 11', 'Khối 12']" [value]="item">{{ item }}</option>
              </select>
            </div>
          </div> -->
          <div class="flex gap-4 flex-col md:flex-row">
            <div class="flex flex-col gap-1 flex-1">
              <span>Số điện thoại</span>
              <input type="text" formControlName="phone" placeholder="VD: 090123456" />
            </div>
            <!-- <div class="flex flex-col gap-1 flex-1">
              <span>Thành phố nơi bạn sinh sống?</span>
              <select formControlName="city">
                <option value="TP Hồ Chí Minh" selected>TP Hồ Chí Minh</option>
                <option value="Hà Nội">Hà Nội</option>
                <option value="An Giang">An Giang</option>
                <option value="Bà Rịa-Vũng Tàu">Bà Rịa-Vũng Tàu</option>
                <option value="Bạc Liêu">Bạc Liêu</option>
                <option value="Bắc Giang">Bắc Giang</option>
                <option value="Bắc Kạn">Bắc Kạn</option>
                <option value="Bắc Ninh">Bắc Ninh</option>
                <option value="Bến Tre">Bến Tre</option>
                <option value="Bình Dương">Bình Dương</option>
                <option value="Bình Định">Bình Định</option>
                <option value="Bình Phước">Bình Phước</option>
                <option value="Bình Thuận">Bình Thuận</option>
                <option value="Cà Mau">Cà Mau</option>
                <option value="Cao Bằng">Cao Bằng</option>
                <option value="Cần Thơ">Cần Thơ</option>
                <option value="Đà Nẵng">Đà Nẵng</option>
                <option value="Đắk Lắk">Đắk Lắk</option>
                <option value="Đắk Nông">Đắk Nông</option>
                <option value="Điện Biên">Điện Biên</option>
                <option value="Đồng Nai">Đồng Nai</option>
                <option value="Đồng Tháp">Đồng Tháp</option>
                <option value="Gia Lai">Gia Lai</option>
                <option value="Hà Giang">Hà Giang</option>
                <option value="Hà Nam">Hà Nam</option>
                <option value="Hà Tĩnh">Hà Tĩnh</option>
                <option value="Hải Dương">Hải Dương</option>
                <option value="Hải Phòng">Hải Phòng</option>
                <option value="Hậu Giang">Hậu Giang</option>
                <option value="Hòa Bình">Hòa Bình</option>
                <option value="Hưng Yên">Hưng Yên</option>
                <option value="Khánh Hòa">Khánh Hòa</option>
                <option value="Kiên Giang">Kiên Giang</option>
                <option value="Kon Tum">Kon Tum</option>
                <option value="Lai Châu">Lai Châu</option>
                <option value="Lạng Sơn">Lạng Sơn</option>
                <option value="Lào Cai">Lào Cai</option>
                <option value="Lâm Đồng">Lâm Đồng</option>
                <option value="Long An">Long An</option>
                <option value="Nam Định">Nam Định</option>
                <option value="Nghệ An">Nghệ An</option>
                <option value="Ninh Bình">Ninh Bình</option>
                <option value="Ninh Thuận">Ninh Thuận</option>
                <option value="Phú Thọ">Phú Thọ</option>
                <option value="Phú Yên">Phú Yên</option>
                <option value="Quảng Bình">Quảng Bình</option>
                <option value="Quảng Nam">Quảng Nam</option>
                <option value="Quảng Ngãi">Quảng Ngãi</option>
                <option value="Quảng Ninh">Quảng Ninh</option>
                <option value="Quảng Trị">Quảng Trị</option>
                <option value="Sóc Trăng">Sóc Trăng</option>
                <option value="Sơn La">Sơn La</option>
                <option value="Tây Ninh">Tây Ninh</option>
                <option value="Thái Bình">Thái Bình</option>
                <option value="Thái Nguyên">Thái Nguyên</option>
                <option value="Thanh Hóa">Thanh Hóa</option>
                <option value="Thừa Thiên Huế">Thừa Thiên Huế</option>
                <option value="Tiền Giang">Tiền Giang</option>
                <option value="Trà Vinh">Trà Vinh</option>
                <option value="Tuyên Quang">Tuyên Quang</option>
                <option value="Vĩnh Long">Vĩnh Long</option>
                <option value="Vĩnh Phúc">Vĩnh Phúc</option>
                <option value="Yên Bái">Yên Bái</option>
              </select>
            </div> -->
            <div class="flex flex-col gap-1 flex-1">
              <span>Ngày sinh của bạn</span>
              <input
                type="text"
                formControlName="birthdate"
                placeholder="VD: 31/12/2006"
                (click)="picker.open()"
                [matDatepicker]="picker"
              />
              <mat-datepicker #picker></mat-datepicker>
            </div>
          </div>
          <hr />
        </form>
        <button
          class="btn primary small self-end"
          [ngClass]="{ shine: processing }"
          (click)="updateProfile(extraForm)"
          [disabled]="processing || isDone"
        >
          @if(isDone){
          <i class="icon-Check"></i>
          }
          {{ isDone ? ' Đã cập nhật' : processing ? 'Đang xử lý' : 'Cập nhật' }}
        </button>
      </div>
      }}
    </div>
  </div>
</div>
<dialog #errorDialog (click)="errorDialog.close()" [open]="error != ''">
  <div class="col w-full items-center gap-6 p-6 pt-10" (click)="$event.stopPropagation()">
    <span class="line-clamp-2">{{ error }}</span>
    <button class="btn primary" (click)="errorDialog.close()">Đã hiểu</button>
  </div>
</dialog>
