<div class="col w-full items-stretch gap-6 p-6 pt-0" (click)="$event.stopPropagation()">
  <div class="col gap-2">
    <h6 class="semi">Mục tiêu bạn tạo không gian này là...</h6>
    <input [(ngModel)]="goal" type="text" placeholder="Ghi lại mục tiêu của bạn...." class="w-full" />
  </div>
  <div class="col gap-6">
    <div class="col gap-2">
      <h6 class="semi">Tên không gian</h6>
      <input
        [(ngModel)]="name"
        type="text"
        placeholder="Ghi lại mục tiêu của bạn...."
        class="w-full"
        [disabled]="processing"
      />
    </div>
    <div class="col gap-2">
      <h6 class="semi">Dùng ngôn ngữ</h6>
      <select [(ngModel)]="language" [disabled]="processing">
        <option [value]="LangKey.VI">Tiếng Việt</option>
        <option [value]="LangKey.EN">Tiếng Anh</option>
      </select>
    </div>
  </div>
  <button class="btn primary" [ngClass]="{ shine: processing }" [disabled]="processing" (click)="edit()">
    {{ processing ? 'Đang xử lý' : 'Hoàn tất' }}
  </button>
  <hr />
  <button class="btn red link" (click)="deleteDialog.showModal()">Xóa không gian</button>
</div>
<dialog #errorDialog (click)="errorDialog.close()" [open]="error != ''">
  <div class="col w-full items-center gap-6 p-6 pt-10" (click)="$event.stopPropagation()">
    <span class="line-clamp-2">{{ error }}</span>
    <button class="btn primary" (click)="errorDialog.close()">Đã hiểu</button>
  </div>
</dialog>
<dialog #deleteDialog (click)="deleteDialog.close()" [open]="error != ''">
  <div class="col w-full items-center gap-6 p-6 pt-10" (click)="$event.stopPropagation()">
    <span>Tiến hành xóa không gian</span>
    <div class="flex justify-between gap-4 w-full">
      <button class="btn primary" (click)="deleteDialog.close()">Suy nghĩ lại</button>
      <button class="btn red" (click)="delete()" [disabled]="processing">
        {{ processing ? 'Đang xử lý' : 'Đồng ý xóa' }}
      </button>
    </div>
  </div>
</dialog>
