import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { LatexComponent } from '@share-components';
import { TopMenuComponent } from '@view/share-components/top-menu/top-menu.component';
import { delay, from, Observable, of, switchMap } from 'rxjs';

@Component({
  standalone: true,
  imports: [FormsModule, LatexComponent, MatIconModule, CommonModule, TopMenuComponent],
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
})
export class TestComponent implements OnInit {
  @HostBinding('class') class = 'app-full';
  isThinking = false;
  prompt = 'Giải bài toán này bằng tiếng Việt, lời giải format chuẩn markdown và latex'
  image: string | null = null;
  image64 = '';
  result = '';
  mimeType = 'image/jpg';

  ngOnInit() {
    const myObservable = of(null).pipe(
      delay(1000), // Wait for 1 second
      switchMap(() => this.myFunction()) // Execute myFunction and return its observable
    );

    myObservable.subscribe({
      next: (result) => {
        console.log(result); // Output: ['result 1', 'result 2']
      },
      error: (error) => {
        console.error('Error:', error);
      }
    }
    );

  }

  myFunction(): Observable<any> {
    // Your function logic here
    return from(['result 1', 'result 2']);
  }

  onCtrlEnter() {
    this.prompt += '\n';
  }

  onEnter() {
    this.ask();
  }

  onFileSelected(event: Event) {
    if (event.target == null) return;
    const target = event.target as HTMLInputElement;
    // const files = target.files as FileList;
    // convertFile(files[0]).subscribe(base64 => {
    //   this.image = base64;
    // });
    const reader = new FileReader();
    if (target.files && target.files[0]) {
      reader.readAsDataURL(target.files[0]);
      reader.onloadend = () => {
        const result = reader.result as string;
        const splits = result.split(',')
        this.image = splits[1];
        this.image64 = reader.result as string;
        if (splits[0].includes('image/png')) {
          this.mimeType = 'image/png'
        }
        else if (splits[0].includes('image/jpg')) {
          this.mimeType = 'image/jpg'
        }
      };
    }
  }

  ask() {
    this.isThinking = true;
  }
}

// function convertFile(file: File): Observable<string> {
//   const result = new ReplaySubject<string>(1);
//   const reader = new FileReader();
//   reader.readAsDataURL(file);
//   reader.onload = (event) => event.target != null && event.target.result != null ? result.next(btoa(event.target.result.toString())) : '';
//   return result;
// }
function splitParagraph(paragraph: string, maxChars: number): string[] {
  const lines = paragraph.split('\n');
  const paragraphs: string[] = [];
  let currentParagraph = '';

  for (const line of lines) {
    if (currentParagraph.length + line.length + 1 <= maxChars) {
      currentParagraph += line + '\n';
    } else {
      paragraphs.push(currentParagraph.trim());
      currentParagraph = line + '\n';
    }
  }

  if (currentParagraph.length > 0) {
    paragraphs.push(currentParagraph.trim());
  }

  return paragraphs;
}
