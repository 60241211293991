import { SelectionModel } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { FileData, FilePlaceholder } from '@share-utils/data';
import { IFileService } from '@share-utils/domain';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'file-selection',
  standalone: true,
  imports: [CommonModule, FormsModule, MatTableModule, MatIconModule, MatPaginatorModule, MatSortModule, MatCheckboxModule],
  templateUrl: './file-selection.component.html',
  styles: [`
.table-responsive {
  display: flex;
  width: 100%;
  overflow-x: auto;
  position: relative;
  border-radius: 10px;
}
  `],
})
export class FileSelectionComponent implements OnInit, AfterViewInit {
  // fileService = inject(FileService);

  private _files: FileData[] = [];
  searchFile = '';
  dataSource: MatTableDataSource<FileData> = new MatTableDataSource<FileData>([]);
  displayedColumns: string[] = ['name'
    , 'mimeType', 'size', 'createdAt'
  ];
  loading = true;
  listFilesSubscription: Subscription | null = null;
  selection = new SelectionModel<FileData>(true, []);

  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator = new MatPaginator(
    new MatPaginatorIntl(),
    ChangeDetectorRef.prototype
  );

  @Input() userId: string = '';
  @Input() fileService!: IFileService;
  @Input() multiple = false;
  @Input() filterExtensions: string[] | null = null;
  @Output() fileClicked = new EventEmitter<FileData>();
  @Output() filesSelected = new EventEmitter<FileData[]>();
  @Output() onClose = new EventEmitter<void>();

  ngOnInit(): void {
    if (this.multiple) {
      this.displayedColumns = ['select', ...this.displayedColumns];
    }
    this.update();
  }

  update() {
    this.loading = true;
    firstValueFrom(this.fileService.listFiles(this.userId)).then((data) => {
      if (this.filterExtensions != null) { data = data.filter(file => this.filterExtensions!.includes(file.extension)) }
      this._files = data.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
      this.dataSource = new MatTableDataSource<FileData>(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.loading = false;
    },
      (err) => {
        console.error(err);
        this.loading = false;
      }
    );
  }

  updateFile(file: FileData) {
    this._files.find(file => file.id === file.id)!.name = file.name;
    this.dataSource.data = this._files;
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.paginator._intl.nextPageLabel = 'Trang kế';
    this.paginator._intl.previousPageLabel = 'Trang trước';
    this.paginator._intl.itemsPerPageLabel = 'Mỗi trang hiện:';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      const start = page * pageSize + 1;
      const end = (page + 1) * pageSize;
      return `${start} - ${end} trong ${length}`;
    };
  }

  onSearchChat() {
    this.dataSource.filter = this.searchFile;
  }

  uploadFile(file: File, image: FilePlaceholder, callback: (data: string) => void, errorCallback: (code: number) => void) {
    return this.fileService.uploadFile(this.userId, file, image).subscribe({
      next: (data) => {
        if (data.id != undefined) {
          callback(data.id);
          setTimeout(() => {
            this.update();
          }, 500);
        }
      },
      error: (err) => {
        errorCallback(err.code);
      }
    })
  }

  deleteFile(fileId: string, success: () => void): Subscription {
    return this.fileService.deleteFile(this.userId, fileId).subscribe({
      next: (result) => {
        if (result) { setTimeout(() => { this.update(); }, 500) }
        success();
        return result;
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  onSelectFiles() {
    this.filesSelected.emit(this.selection.selected);
    this.onClose.emit();
  }
}
