<student-loading *ngIf="isLoading"></student-loading>
<div class="flex w-full justify-center">
  <div class="col gap-4 w-full max-w-[1122px] p-6 md:p-12 h-screen">
    <button [routerLink]="[paths.learningPath.path]" class="icon-btn self-end md:hidden">
      <i class="icon-XClose"></i>
    </button>
    <div class="flex items-center justify-between w-full">
      <div class="flex gap-4 items-center">
        <button
          [routerLink]="[paths.learningPath.path]"
          class="no-underline gap-2 items-center text-primary hidden md:flex"
        >
          <i class="icon-ChevronLeft"></i>
          <span>Về lộ trình học</span>
        </button>
        <h4>{{ learningGoal.name }}</h4>
      </div>
      <div class="hidden md:flex gap-2">
        <student-top-menu [showLogo]="false" />
      </div>
    </div>
    <div class="col rounded-lg bg-white p-4 gap-4 overflow-y-auto md:flex-1 h-[calc(100vh_-_280px)]" *ngIf="!isLoading">
      <div class="flex items-center justify-between w-full">
        <h6>
          Câu {{ currentQuestionIndex + 1 }}
          <!-- <span *ngIf="!questions[currentQuestionIndex].isCorrectAnswer" class="text-red-0"
            >Chưa phải đáp án đúng!</span
          ><span *ngIf="questions[currentQuestionIndex].isCorrectAnswer" class="text-darkEmerald"
            >Đáp án chính xác!</span
          > -->
        </h6>
        <div class="flex-1"></div>
        <div class="flex gap-4 items-center">
          <a href="https://forms.gle/b36mTdMWNRY1qkZh7" target="_blank" class="outlined hidden md:flex">Báo lỗi</a>
          <a
            href="https://forms.gle/b36mTdMWNRY1qkZh7"
            target="_blank"
            class="btn-icon primary outlined block md:hidden"
            ><i class="icon-Error"></i
          ></a>
          <button class="btn primary" (click)="showLesson = true">
            <i class="icon-Crown text-white"></i>
            Xem bài học
          </button>
        </div>
      </div>
      <kyonsvn-test-review [questions]="questions" [currentQuestionIndex]="currentQuestionIndex" />
    </div>
    <div class="justify-between flex flex-col gap-3">
      <div class="flex gap-4 items-start w-full overflow-hidden">
        <button (click)="scrollLeft()" [disabled]="currentQuestionIndex === 0" class="btn hidden md:flex">
          Câu trước
        </button>
        <button (click)="scrollLeft()" [disabled]="currentQuestionIndex === 0" class="btn-icon link flex md:hidden">
          <i class="icon-ChevronLeft"></i>
        </button>
        <div class="flex-1 flex gap-1 w-full overflow-hidden">
          <button class="btn-icon primary outlined hidden md:flex" (click)="previousPage()">
            <i class="icon-ChevronLeft"></i>
          </button>
          <div
            #scrollElm
            data-tooltip-content="Bạn có thể chuyển đến đáp án mong muốn bằng cách nhấn vào số của câu hỏi"
            class="w-full md:w-[calc(100%_-_92px)] overflow-x-auto flex gap-1 hide-scrollbar"
          >
            <button
              class="btn-icon primary outlined"
              *ngFor="let question of questions; index as i"
              [ngClass]="{
                darkEmerald: question.isCorrectAnswer,
                red: !question.isCorrectAnswer,
                outlined: i !== currentQuestionIndex,
              }"
              (click)="currentQuestionIndex = i"
            >
              {{ i + 1 }}
            </button>
          </div>
          <button class="btn-icon primary outlined hidden md:flex" (click)="nextPage()">
            <i class="icon-ChevronRight"></i>
          </button>
        </div>
        <button
          [disabled]="currentQuestionIndex === questions.length - 1"
          (click)="scrollRight()"
          class="btn hidden md:flex"
        >
          Câu kế tiếp
        </button>
        <button
          (click)="scrollRight()"
          [disabled]="currentQuestionIndex === questions.length - 1"
          class="btn-icon link flex md:hidden"
        >
          <i class="icon-ChevronRight"></i>
        </button>
      </div>
    </div>
    <!-- <div class="flex bg-lightOrange-4 w-full h-[56px] items-center justify-between px-6 rounded-lg">
      <span><b class="text-primary">Bạn muốn cải thiện điểm số?</b> Bấm <b>Tạo lộ trình học</b> để bắt đầu ngay!</span>
      <button
        [disabled]="status !== MockTestStatus.learning_path_received"
        (click)="activateLearningPath()"
        class="btn primary"
      >
        Tạo lộ trình học
      </button>
    </div> -->
  </div>
</div>
<div
  [ngClass]="{ flex: showLesson, hidden: !showLesson }"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[800px] items-center">
    <div class="col w-full gap-6">
      <div class="flex justify-between">
        <h3>Nội dung bài học</h3>
        <button class="btn-icon primary outlined" (click)="showLesson = false"><i class="icon-XClose"></i></button>
      </div>
      <!-- <p [innerHTML]="content | safeHtml"></p> -->
      <!-- <object #contentElm data="" type="application/pdf" width="100%" height="100%" style="height: 80vh">
        <p>Alternative content for users without PDF support.</p>
      </object> -->
      @if(content===''){
      <div class="">Bài học chưa cập nhật nội dung</div>
      } @else {
      <div class="relative w-full bg-[#313131]">
        <ng-lottie
          [options]="options"
          width="100px"
          height="100px"
          class="absolute top-[calc(50%_-_50px)] left-[calc(50%_-_50px)]"
        ></ng-lottie>
        <embed
          [src]="content | safeResourceUrl"
          width="100%"
          height="100%"
          class="w-full h-[80vh] min-h-[80vh] relative"
        />
      </div>
      }

      <!-- <embed [src]="content" width="100%" height="100%" style="height: 80vh" /> -->
      <button (click)="showLesson = false" class="btn primary w-full">Đã hiểu</button>
    </div>
  </div>
</div>
