import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  OnInit,
  Output,
  ViewChild,
  inject
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChatService } from '@data/chat/chat.service';
import { NavigationService } from '@data/navigation/navigation.service';
import { OrderService } from '@data/order/order.service';
import { ThemeService } from '@data/theme/theme.service';
// import { NotificationService } from '@data/notification/notification.service';
import { UserService } from '@data/user/user.service';
import { TopRightMenuComponent } from '../top-right-menu/top-right-menu.component';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, TopRightMenuComponent],
  selector: 'student-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss'],
})
export class MenuComponent implements OnInit {
  paths = inject(NavigationService).paths;
  userService = inject(UserService);
  themeService = inject(ThemeService);
  chatService = inject(ChatService);
  injector = inject(Injector);
  orderService = inject(OrderService);
  // taskAnimationController = inject(TaskAnimationController);

  isShowMobileMenu = false;
  show = true;
  // manaController = inject(ManaController);
  // mana = this.manaController.mana$;
  // coin = this.taskAnimationController.coinDisplay$;
  isCollapsed = false;
  currentUser = this.userService.currentUser;

  /**
   * This is the toogle button elemenbt, look at HTML and see its defination
   */
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  @ViewChild('menu') menu!: ElementRef;
  // @ViewChild('coinInTopMenuPosition') coinInTopMenuPosition!: ElementRef;
  // @ViewChild('dialog') dialogElm!: ElementRef<HTMLDialogElement>;

  @Output('collapseEvent') collapseEvent = new EventEmitter<boolean>();

  // @HostListener('window:resize', ['$event'])
  // onResize(event: any): void {
  //   this.taskAnimationController.coinInTopMenuPosition = {
  //     x: this.coinInTopMenuPosition.nativeElement.x + 12,
  //     y: this.coinInTopMenuPosition.nativeElement.y + 12
  //   };
  // }

  username = '';
  showSettings = false;

  async ngOnInit(): Promise<void> {
    // this.manaController.refresh();
    // this.taskAnimationController.refreshCoinValue();
    // runInInjectionContext(this.injector, () => {
    //   effect(() => {
    //     this.skin = this.themeService.skinStore();
    //     this.theme = this.themeService.getTheme();
    //   });
    // });
  }


  // ngAfterViewInit(): void {
  //   this.taskAnimationController.coinInTopMenuPosition = {
  //     x: this.coinInTopMenuPosition.nativeElement.x + 12,
  //     y: this.coinInTopMenuPosition.nativeElement.y + 12
  //   };
  // }

  collapse(): void {
    this.isCollapsed = !this.isCollapsed;
    this.collapseEvent.emit(this.isCollapsed);
  }

  close() {
    setTimeout(() => {
      this.isShowMobileMenu = false
    }, 100)
  }


  // showDialog(text: string) {
  //   this.dialogText = text;
  //   this.dialogElm.nativeElement.showModal();
  // }
}
