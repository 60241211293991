import { IWorkspace } from "@domain/workspace/i-workspace";
import { FileData, FilePlaceholder, SourceStatus } from "@share-utils/data";
import { INote, ISource } from "@share-utils/domain";
import { formattedDate2, toHashTag } from "@share-utils/utils";
import { pick } from "lodash-es";

class Workspace implements IWorkspace {
  id: string;
  name: string;
  goal: string;
  summary: string;
  keywords: string[];
  suggestions: string[];
  createdAt: Date;
  createdAtDisplay: string;
  lastVisited?: Date;
  lastVisitedDisplay?: string;
  // sources: WorkspaceSource[] = [];
  language: LangKey;
  searchIndex: string;
  onlySource: boolean;
  constructor({
    id,
    name,
    goal,
    createdAt,
    lastVisited,
    summary,
    keywords,
    suggestions,
    language,
    onlySource,
  }: {
    id: string;
    name: string;
    goal: string,
    summary: string,
    keywords: string[],
    suggestions: string[],
    createdAt: Date;
    lastVisited?: Date;
    language: LangKey,
    onlySource: boolean
  }) {
    this.id = id;
    this.name = name;
    this.createdAt = createdAt;
    this.createdAtDisplay = formattedDate2(createdAt);
    this.lastVisited = lastVisited;
    this.lastVisitedDisplay = lastVisited ? formattedDate2(lastVisited) : undefined;
    this.goal = goal;
    this.summary = summary;
    this.keywords = keywords;
    this.suggestions = suggestions;
    this.language = language;
    this.searchIndex = name + ' ' + goal + ' ' + summary + ' ' + keywords.join(' ') + ' ' + suggestions.join(' ');
    this.onlySource = onlySource;
  }

  static fromJson(jsonObject: any): Workspace {
    const _ = pick(jsonObject, ['id', 'name', 'createdAt', 'lastVisited', 'goal', 'summary', 'keywords', 'suggestions', 'sources', 'language', 'onlySource']);
    _.createdAt = new Date(jsonObject['createdAt']._seconds * 1000);
    _.lastVisited = jsonObject['lastVisited'] ? new Date(jsonObject['lastVisited']._seconds * 1000) : undefined;
    return new Workspace(_);
  }

  static empty(): Workspace {
    return new Workspace({ id: '', name: '', createdAt: new Date(), lastVisited: new Date(), goal: '', summary: '', keywords: [], suggestions: [], language: LangKey.VI, onlySource: false });
  }

  update(newData: { name?: string, goal?: string, onlySource?: boolean, language?: string }): void {
    Object.assign(this, newData);
  }
}

type WorkspaceSource = {
  id: string;
  using: boolean;
}

class Note implements INote {
  id: string;
  name: string;
  content: string;
  createdAt: Date;
  createdAtDisplay: string;
  updatedAt: Date;
  updatedAtDisplay: string;

  constructor({
    id,
    name,
    content,
    createdAt,
    updatedAt }: {
      id: string;
      name: string;
      content: string;
      createdAt: Date;
      updatedAt: Date;
    }) {
    this.id = id;
    this.name = name;
    this.content = content;
    this.createdAt = createdAt;
    this.createdAtDisplay = formattedDate2(createdAt);
    this.updatedAt = updatedAt;
    this.updatedAtDisplay = formattedDate2(updatedAt);
  }

  static fromJson(jsonObject: any): Note {
    const _ = pick(jsonObject, ['id', 'name', 'content', 'createdAt', 'updatedAt']);
    _.createdAt = new Date(jsonObject['createdAt']._seconds * 1000);
    _.updatedAt = new Date(jsonObject['updatedAt']._seconds * 1000);
    return new Note(_);
  }
}

class Source implements ISource {
  id: string;
  name: string;
  summary: string;
  transcript: { text: string, page: number }[];
  keywords: string[];
  fileId: string;
  publicUrl: string;
  createdAt: Date;
  createdAtDisplay: string;
  using: boolean = true;
  processing: boolean = false;
  hashTag: string;
  status: SourceStatus;
  constructor({
    id,
    name,
    summary,
    transcript,
    keywords,
    fileId,
    publicUrl,
    createdAt,
    using,
    processing,
    status
  }: {
    id: string;
    name: string;
    summary: string;
    transcript: { text: string, page: number }[];
    keywords: string[];
    fileId: string;
    publicUrl: string;
    createdAt: Date;
    using: boolean;
    processing?: boolean;
    status: SourceStatus;
  }) {
    this.id = id;
    this.name = name;
    this.summary = summary;
    this.transcript = transcript;
    this.keywords = keywords;
    this.fileId = fileId;
    this.publicUrl = publicUrl;
    this.createdAt = createdAt;
    this.createdAtDisplay = formattedDate2(createdAt);
    this.using = using;
    this.hashTag = toHashTag(name);
    this.processing = processing ?? false;
    this.status = status;
  }

  static fromJson(jsonObject: any): Source {
    const _ = pick(jsonObject, ['id', 'name', 'summary', 'text', 'keywords', 'fileId', 'publicUrl', 'createdAt', 'using', 'transcript', 'status']);
    _.createdAt = new Date(jsonObject['createdAt']._seconds * 1000);
    return new Source(_);
  }

  static empty(): Source {
    return new Source({ id: '', name: '', summary: '', transcript: [], keywords: [], fileId: '', publicUrl: '', createdAt: new Date(), using: true, status: SourceStatus.created });
  }

  static fromFileData(fileData: FileData) {
    return new Source({
      id: fileData.id,
      name: fileData.name,
      summary: '',
      transcript: [],
      keywords: [],
      fileId: fileData.id,
      publicUrl: fileData.fileUri,
      createdAt: new Date(),
      using: true,
      processing: true,
      status: SourceStatus.created,
    });
  }

  static fromFilePlaceholder(filePlaceholder: FilePlaceholder) {
    return new Source({
      id: filePlaceholder.id,
      name: filePlaceholder.name,
      summary: '',
      transcript: [],
      keywords: [],
      fileId: filePlaceholder.name,
      publicUrl: '',
      createdAt: new Date(),
      using: true,
      processing: true,
      status: SourceStatus.created,
    });
  }

  toJson() {
    return {
      id: this.id,
      name: this.name,
      summary: this.summary,
      text: this.transcript,
      keywords: this.keywords,
      fileId: this.fileId,
      publicUrl: this.publicUrl,
      createdAt: this.createdAt,
      using: this.using,
      processing: this.processing,
    }
  }
}

enum LangKey {
  EN = 'en',
  VI = 'vi',
}

export { LangKey, Note, Source, Workspace };

