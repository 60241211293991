// import { Injectable, inject } from '@angular/core';
// import { Firestore } from 'firebase/firestore';
import { HttpBackend, HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { DBHelper } from '@data/helper/helper';
import { LangKey } from '@data/workspace/workspace-models';
import { environment } from '@environments';
import { AIModel, Chat, CommandContent, Content, FileData, FilePlaceholder, Mana } from '@share-utils/data';
import { IChatService, IGeneralChatService } from '@share-utils/domain';
import { Observable, catchError, map, of } from 'rxjs';

const chatServerApi = environment.chatApi;
// const chatServerApi = 'http://127.0.0.1:5001/kyonsvn-stg/asia-east1/chatApi';

@Injectable({
  providedIn: 'root',
})
class ChatService implements IChatService, IGeneralChatService {
  db = inject(Firestore);
  http = inject(HttpClient);
  backend = inject(HttpBackend);

  context = '';
  data = {};

  getGreeting(): Observable<Content> {
    const params = new HttpParams().set('prompt', '/hello');
    return this.http.get(`${chatServerApi}/greet`, { params: params }).pipe(
      catchError(DBHelper.handleError('GET getGreeting', Content.outOfMana())),
      map((res: any) => {
        return res.data !== undefined && res.data['text'] !== undefined ? Content.parseContent({
          id: '',
          role: 'model',
          parts: [{ text: res.data['text'] }],
          createdAt: {
            _seconds: Date.now() / 1000,
          },
        }) : Content.outOfMana();
      })
    );
  }
  resetLessonChat(userId: any, lessonId: string) {
    return this.http.put(`${chatServerApi}/user/${userId}/resetLessonChat/${lessonId}`, null);
  }

  sendMessage(userId: string, message: string, model: string, { context, file, image, chatId, fileData, responseLanguage = LangKey.VI }: { chatId?: string, context?: string, file?: File, image?: FilePlaceholder, fileData?: FileData, responseLanguage?: LangKey } = {}): Observable<string> {
    const formData = new FormData();
    formData.append('prompt', message);
    formData.append('userId', userId);
    formData.append('model', model);
    if (chatId) formData.append('chatId', chatId);
    if (file) formData.append('file', file);
    if (image) {
      formData.append('fileName', image.name);
      formData.append('mimeType', image.mimeType);
    }
    if (fileData) {
      formData.append('fileId', fileData.id);
      formData.append('bucketId', fileData.bucketId);
    }
    if (context) formData.append('context', context.replace('[data]', JSON.stringify(this.data)));
    if (responseLanguage) formData.append('responseLanguage', responseLanguage);
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
      Accept: 'application/json',
    });
    // return new Observable();
    return this.http.post(`${chatServerApi}/askFile${fileData ? 'Storage' : ''}`, formData, { headers }).pipe(
      // catchError(DBHelper.handleError('POST sendMessage', [Content.outOfMana()])),
      map((res: any) => {
        if (res['chatId'] === undefined) return '';
        return res['chatId'];
      })
    );
  }

  sendMathMessage(userId: string, message: string, { chatId }: { chatId?: string } = {}): Observable<string> {
    const formData = new FormData();
    formData.append('prompt', message);
    formData.append('userId', userId);
    if (chatId) formData.append('chatId', chatId);
    const headers = new HttpHeaders({
      enctype: 'multipart/form-data',
      Accept: 'application/json',
    });
    return this.http.post(`${chatServerApi}/askMath`, formData, { headers }).pipe(
      map((res: any) => {
        if (res['data'] === undefined) return '';
        return res['data'];
      })
    );
  }

  getMana(userId: string): Observable<Mana> {
    return this.http.get(`${chatServerApi}/user/${userId}/mana`).pipe(
      catchError(DBHelper.handleError('GET getMana', Mana.invalid())),
      map((res: any) => {
        if (res['mana'] === undefined || res['defaultMana'] === undefined) return Mana.invalid();
        return new Mana(res['mana'], res['defaultMana']);
      })
    );
  }

  getMessages(userId: string, chatId: string): Observable<Content[]> {
    return this.http.get(`${chatServerApi}/user/${userId}/chat/${chatId}`).pipe(
      map((res: any) => {
        if (res.data === undefined) return [];
        return (res.data as any[]).map((data: any) => Content.parseContent(data));
      })
    );
  }

  getChats(userId: string, forceReload = false): Observable<Chat[]> {
    if (window.localStorage.getItem('chats') && !forceReload) {
      const chats = JSON.parse(window.localStorage.getItem('chats') as string);
      return new Observable<Chat[]>((subscriber) => {
        subscriber.next(chats.map((data: any) => {
          return Chat.fromJson(data);
        }));
        subscriber.complete();
      });
    }
    else {
      window.localStorage.removeItem('chats');
    }
    return this.http.get(`${chatServerApi}/user/${userId}/chats`).pipe(
      catchError(DBHelper.handleError('GET getChats', [])),
      map((res: any) => {
        if (res.data === undefined || res.data.length === 0) return [];
        // const collection = res.data;
        window.localStorage.setItem('chats', JSON.stringify(res.data));
        const collection = (res.data as any[]).map((data: any) => {
          return Chat.fromJson(data);
        });
        return collection;
      })
    );
  }

  checkCreatedUser(userId: string) {
    return this.http.get(`${chatServerApi}/user/${userId}/mana`);
  }

  removeChatCache() {
    window.localStorage.removeItem('chats');
  }

  removeCache() {
    window.localStorage.removeItem('chats');
    window.localStorage.removeItem('files');
  }
  updateChatName(userId: string, chatId: string, chatName: string): Observable<any> {
    console.log(userId, chatId, chatName);
    return this.http.put(`${chatServerApi}/user/${userId}/updateChat/${chatId}`, { firstMessage: chatName });
  }

  deleteChat(userId: string, chatId: string) {
    return this.http.delete(`${chatServerApi}/user/${userId}/deleteChat/${chatId}`).pipe(
      map((res: any) => {
        console.log(res);
        if (res.success) {
          window.localStorage.removeItem('chats');
        }
        return res;
      })
    );
  }


  getChatsByIds(userId: string, ids: string[]): Observable<Chat[]> {
    if (window.localStorage.getItem('chats')) {
      const chats = JSON.parse(window.localStorage.getItem('chats') as string) as any[];
      return new Observable<Chat[]>((subscriber) => {
        subscriber.next(
          chats.map((data: any) => {
            return Chat.fromJson(data);
          }).filter((chat) => ids.includes(chat.id))
        );
        subscriber.complete();
      });
    }
    else {
      window.localStorage.removeItem('chats');
    }
    return this.http.get(`${chatServerApi}/user/${userId}/chats`).pipe(
      catchError(DBHelper.handleError('GET getChats', [])),
      map((res: any) => {
        if (res.data === undefined || res.data.length === 0) return [];
        // const collection = res.data;
        window.localStorage.setItem('chats', JSON.stringify(res.data));
        const collection = (res.data as any[]).map((data: any) => {
          return Chat.fromJson(data);
        });
        return collection.filter((chat) => ids.includes(chat.id));
      })
    );
  }

  likeContent(userId: string, chatId: string, messageId: string, like: number): Observable<boolean> {
    const params = {
      userId,
      chatId,
      messageId,
      like,
    }
    return this.http.post(`${chatServerApi}/likeMessage`, params).pipe(
      map((res: any) => {
        if (res.success) {
          return true;
        }
        return false;
      })
    );
  }

  deleteMessageFrom(userId: string, chatId: string, createdAt: Date): Observable<boolean> {
    return this.http.delete(`${chatServerApi}/user/${userId}/chat/${chatId}/deleteMessagesFrom/${createdAt.getTime()}`).pipe(
      map((res: any) => {
        if (res.success) {
          return true;
        }
        return false;
      })
    );
  }

  deleteMessage(userId: string, workspaceId: string, id: string): Observable<boolean> {
    return this.http.delete(`${chatServerApi}/user/${userId}/chat/${workspaceId}/deleteMessage/${id}`).pipe(
      map((res: any) => {
        if (res.success) {
          return true;
        }
        return false;
      })
    )
  }

  sendCommand(command: string, model: AIModel, { commandCallback }: { commandCallback?: () => void } = {}): Observable<CommandContent | string> {
    const commandMessage = new CommandContent(command, model);
    if (commandMessage.commandMessage == '') {
      const params = new HttpParams().set('command', command);
      return this.http.get(`${chatServerApi}/game`, { params }).pipe(
        map((res: any) => {
          if (res.data == undefined) return CommandContent.noCommandFound(model);
          return res.data;
        })
      )
    }
    else {
      return of(commandMessage);
    }

  }

  isCommand(message: string) {
    if (message.startsWith('/')) {
      return true;
    }
    return false;
  }

  getModels(): Observable<AIModel[]> {
    return this.http.get(`${chatServerApi}/models`).pipe(
      map((res: any) => {
        if (res.data === undefined) return [];
        return Object.values(res.data).map((jsonObject: any) => {
          return AIModel.fromJson(jsonObject);
        });
      })
    )
  }

  getPromptHelper(prompt: string, idea: string) {
    return this.http.post(`${chatServerApi}/promptHelper`, {
      prompt,
      idea
    })
  }

}

export { ChatService, chatServerApi };

// const chatConverter = {
//   toFirestore(value: WithFieldValue<Chat>) {
//     return { value };
//   },
//   fromFirestore(snapshot: QueryDocumentSnapshot) {
//     console.log(snapshot.id);

//     return Chat.fromJson(snapshot.id, snapshot.data()['createdAt'].toDate());
//   },
// };
