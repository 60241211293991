function searchTags(query: string, strings: string[]): boolean[] {
  query = query.replace(/[@#]/g, '').toLocaleLowerCase();

  const matches: number[] = strings.map(str => 0);
  const searchs = strings.map(str => str.replace(/[@#]/g, '').toLocaleLowerCase());

  for (const char of query) {
    for (let i = 0; i < searchs.length; i++) {
      while (searchs[i].length > 0) {
        if (searchs[i][0] == char) {
          matches[i]++;
          searchs[i] = searchs[i].slice(1, searchs[i].length);
          break;
        }
        else {
          searchs[i] = searchs[i].slice(1, searchs[i].length);
        }
      }
    }

  }

  return matches.map((matchLength) => matchLength == query.length);
}

export { searchTags };

