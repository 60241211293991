import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'skin-default',
  standalone: true,
  template: '',
  styleUrls: ['../scss/themes/skin/default/default.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkinDefaultComponent {
}