<div class="flex w-full h-full justify-center kyons-bg">
  <div class="col gap-4 w-full max-w-[1122px] p-6 md:p-12 h-full">
    <button
      #backXs
      data-tooltip-content="Bấm vào đây để quay lại trang đáp án bài kiểm tra nhé!"
      data-tooltip-position="bottom"
      (click)="backToResult()"
      class="icon-btn md:hidden"
    >
      <i class="icon-ChevronLeft"></i>
    </button>
    <div class="flex items-center justify-between w-full">
      <div class="flex gap-4 items-center">
        <button
          #back
          data-tooltip-content="Bấm vào đây để quay lại trang đáp án bài kiểm tra nhé!"
          data-tooltip-position="bottom"
          (click)="backToResult()"
          class="no-underline gap-2 items-center text-primary hidden md:flex"
        >
          <i class="icon-ChevronLeft"></i>
          <span>Về Điểm bài kiểm tra</span>
        </button>
        <h4>Đáp án bài kiểm tra</h4>
      </div>
      <!-- <div class="gap-2 hidden md:flex">
        <student-top-menu [showLogo]="false" />
      </div> -->
    </div>
    <div class="col rounded-lg bg-white p-4 gap-4 overflow-y-auto md:flex-1 h-[calc(100vh_-_280px)]" *ngIf="!isLoading">
      <div class="flex items-start justify-between w-full">
        <h6 class="hidden md:flex">
          Câu {{ currentQuestionIndex + 1 }}/{{ questions.length }}&nbsp;-&nbsp;
          <span *ngIf="!questions[currentQuestionIndex].isCorrectAnswer" class="text-red-0">Chưa phải đáp án đúng!</span
          ><span *ngIf="questions[currentQuestionIndex].isCorrectAnswer" class="text-darkEmerald"
            >Đáp án chính xác!</span
          >
        </h6>
        <span class="h8 md:hidden">
          Câu {{ currentQuestionIndex + 1 }}/{{ questions.length }}&nbsp;-&nbsp;
          <span *ngIf="!questions[currentQuestionIndex].isCorrectAnswer" class="text-red-0">Chưa phải đáp án đúng!</span
          ><span *ngIf="questions[currentQuestionIndex].isCorrectAnswer" class="text-darkEmerald"
            >Đáp án chính xác!</span
          >
        </span>
        <div class="hidden md:flex">
          <a href="https://forms.gle/b36mTdMWNRY1qkZh7" target="_blank" class="btn primary outlined small">Báo lỗi</a>
        </div>
        <div class="md:hidden">
          <a href="https://forms.gle/b36mTdMWNRY1qkZh7" target="_blank" class="btn-icon primary outlined small"
            ><i class="icon-Error"></i
          ></a>
        </div>
      </div>
      <kyonsvn-test-review [questions]="questions" [currentQuestionIndex]="currentQuestionIndex" />
    </div>
    <div class="flex gap-4 items-start w-full overflow-hidden">
      <div class="hidden md:flex">
        <button (click)="scrollLeft()" [disabled]="currentQuestionIndex === 0" class="btn secondary">Câu trước</button>
      </div>
      <div class="md:hidden">
        <button (click)="scrollLeft()" [disabled]="currentQuestionIndex === 0" class="btn-icon secondary outlined">
          <i class="icon-ChevronLeft"></i>
        </button>
      </div>
      <div class="flex-1 flex gap-1 w-full overflow-hidden">
        <div class="hidden md:flex">
          <button class="btn-icon primary outlined" (click)="previousPage()">
            <i class="icon-ChevronLeft"></i>
          </button>
        </div>
        <div
          #scrollElm
          data-tooltip-content="Bạn có thể chuyển đến đáp án mong muốn bằng cách nhấn vào số của câu hỏi"
          class="w-full md:w-[calc(100%_-_92px)] overflow-x-auto flex gap-1 hide-scrollbar"
        >
          <button
            class="btn-icon"
            *ngFor="let question of questions; index as i"
            [ngClass]="{
              darkEmerald: question.isCorrectAnswer,
              red: !question.isCorrectAnswer,
              outlined: i !== currentQuestionIndex,
            }"
            (click)="currentQuestionIndex = i"
          >
            {{ i + 1 }}
          </button>
        </div>
        <div class="hidden md:flex">
          <button class="btn-icon primary outlined" (click)="nextPage()">
            <i class="icon-ChevronRight"></i>
          </button>
        </div>
      </div>
      <div class="hidden md:flex">
        <button
          [disabled]="currentQuestionIndex === questions.length - 1"
          (click)="scrollRight()"
          class="btn secondary"
        >
          Câu kế tiếp
        </button>
      </div>
      <div class="md:hidden">
        <button
          (click)="scrollRight()"
          [disabled]="currentQuestionIndex === questions.length - 1"
          class="btn-icon secondary outlined"
        >
          <i class="icon-ChevronRight"></i>
        </button>
      </div>
    </div>
    <div class="hidden md:flex bg-lightOrange-4 w-full h-[56px] items-center justify-between px-6 rounded-lg">
      <span
        ><b class="text-primary">Bạn muốn cải thiện điểm số?</b>&nbsp;
        <span class="!text-primaryBlue">Bấm <b>Tạo lộ trình học</b> để bắt đầu ngay!</span></span
      >
      <button
        [disabled]="status !== MockTestStatus.mock_test_submitted"
        (click)="activateLearningPath()"
        class="btn primary"
      >
        Tạo lộ trình học
      </button>
    </div>
  </div>
</div>
<kyonsvn-tutorial
  *ngIf="showTutorial"
  [scriptElements]="[scrollElm, back]"
  [scriptEvents]="[['click', emptyFunc], ['click', nextTutorial], null]"
  class="hidden md:block"
></kyonsvn-tutorial>
<kyonsvn-tutorial
  *ngIf="showTutorial"
  (skipCallback)="skip()"
  [scriptElements]="[scrollElm, backXs]"
  [scriptEvents]="[['click', emptyFunc], ['click', nextTutorial], null]"
  class="md:hidden"
  (skipCallback)="skip()"
></kyonsvn-tutorial>
