<div
  class="ai-menu-wrapper border-default"
  [ngClass]="{ showMobileMenu: isShowMobileMenu, isCollapsed, isShowDropdown }"
  (click)="isShowMobileMenu = false"
>
  <div class="md:hidden relative placeholder ml-[-0.25rem] mt-1">
    <div>
      <a class="ai-menu-item text- hidden" [routerLink]="paths.workspace.path" routerLinkActive="active"
        >🚀 Không gian làm việc</a
      >
      <a class="ai-menu-item text- hidden" [routerLink]="paths.chatbot.path" routerLinkActive="active"
        >🗣️ Chat với Kyo</a
      >
      <a class="ai-menu-item text- hidden" [routerLink]="paths.promptHelper.path" routerLinkActive="active"
        >Prompt Helper</a
      >
    </div>
    @if(!isShowDropdown){
    <div class="absolute w-full h-full top-0 left-0 z-[1]" (click)="isShowDropdown = true"></div>
    }
  </div>
  <div class="ai-menu-container ai-menu-bg dropdownMenu">
    <a
      class="ai-menu-item text- z-20"
      [routerLink]="paths.workspace.path"
      routerLinkActive="active"
      (click)="isShowDropdown = false"
      >🚀 Không gian làm việc</a
    >
    <a
      class="ai-menu-item text- z-20"
      [routerLink]="paths.chatbot.path"
      routerLinkActive="active"
      (click)="isShowDropdown = false"
      >🗣️ Chat với Kyo</a
    >
    <a
      class="ai-menu-item text- z-20"
      [routerLink]="paths.promptHelper.path"
      routerLinkActive="active"
      (click)="isShowDropdown = false"
      >Prompt Helper</a
    >
    <div class="fixed top-0 left-0 h-screen w-screen z-10 md:hidden" (click)="isShowDropdown = false"></div>
  </div>
  <top-right-menu [backToKyons]="true" />
</div>
