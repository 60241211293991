<div class="col gap-2 md:flex-1 md:max-h-screen overflow-auto">
  <kyonsvn-latex [inputString]="questions[currentQuestionIndex].content"></kyonsvn-latex>
  <!-- <span [innerHTML]="questions[currentQuestionIndex].html | safeHtml"></span> -->
  @for (answer of questions[currentQuestionIndex].answers; track answer.id; let ai = $index){
  <div
    class="flex p-2"
    [ngClass]="{
      'rounded-lg !text-primaryBlue':
        answer.id === questions[currentQuestionIndex].correctAnswer ||
        answer.id === questions[currentQuestionIndex].selectedAnswer,
      'bg-emerald-3': answer.id === questions[currentQuestionIndex].correctAnswer,
      'bg-red-1':
        answer.id === questions[currentQuestionIndex].selectedAnswer &&
        answer.id !== questions[currentQuestionIndex].correctAnswer
    }"
  >
    <kyonsvn-input-radio
      [style.display]="ai > 3 ? 'none' : 'block'"
      [checked]="answer.id === questions[currentQuestionIndex].selectedAnswer"
      [answer]="answer"
      [label]="answerPrefixes[ai] + answer.content"
      [disabled]="true"
    ></kyonsvn-input-radio>
  </div>
  }

  <!-- <span [innerHTML]="questions[currentQuestionIndex].explanation | safeHtml"></span> -->
  <h5>Lời giải</h5>
  <kyonsvn-latex [inputString]="questions[currentQuestionIndex].explanation"></kyonsvn-latex>
</div>
