enum ThemeType {
  light = 'light',
  dark = 'dark',
}
enum SkinType {
  default = 'default',
  universe = 'universe'
}

export { SkinType, ThemeType };

