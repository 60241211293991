<student-tracking-lesson trackingType="on_learning" [lessonId]="lessonId"></student-tracking-lesson>
<div class="w-full on-screen flex justify-center h-full">
  <div class="w-full max-w-[1122px] col gap-4 p-6 h-screen">
    <button [routerLink]="paths.learningPath.path" class="icon-btn self-end md:hidden">
      <i class="icon-XClose"></i>
    </button>
    <div class="flex items-center justify-between w-full">
      <div class="flex gap-4 items-center">
        <button
          [routerLink]="paths.learningPath.path"
          class="no-underline gap-2 items-center text-primary hidden md:flex"
        >
          <i class="icon-ChevronLeft"></i>
          <span>Về lộ trình học</span>
        </button>
        <h4>{{ learningGoal.name }}</h4>
      </div>
      <!-- <div class="flex gap-2">
        <a [routerLink]="paths.home.path" class="menu-item">
      <img src="/assets/icons/Home.svg" alt="" />
      <span>Trang chủ</span>
    </a>
    <a [routerLink]="paths.account.path" class="menu-item">
      <img src="/assets/icons/Profile.svg" alt="" />
      <span>Tài khoản</span>
    </a>
      </div> -->
    </div>
    <div
      #exerciseElm
      class="col rounded-lg bg-white p-4 gap-4 overflow-y-auto md:overflow-hidden md:flex-1 h-[calc(100vh_-_200px)] relative"
    >
      <div class="flex w-full items-center justify-center gap-6">
        <div class="col gap-1 w-full">
          <div class="flex justify-between">
            <!-- <h6 class="hidden md:block">{{ progressStr }}%</h6> -->
            <div class=""></div>
            <div class="flex gap-2 justify-center">
              <span class="h7 !leading-8">Độ khó:</span>
              <div class="star" *ngFor="let level of stars"><img src="/assets/images/star.svg" /></div>
              <div class="star" *ngFor="let level of emptyStars"><img src="/assets/images/EmptyStar.svg" /></div>
            </div>
          </div>
          <!-- <span class="h8 md:hidden">{{ progressStr }}%</span>
          <kyonsvn-questions-progress
            [progress]="progress"
            class="w-full"
            [hideLabel]="true"
          ></kyonsvn-questions-progress> -->
        </div>
        <div class="flex gap-2">
          <a class="btn-icon primary outlined" (click)="openChat()">
            <i class="icon-HintDocument"></i>
          </a>
          <button class="btn primary" (click)="showLesson = true">
            <i class="icon-Crown text-white"></i>
            Xem bài học
          </button>
        </div>
      </div>
      <div #scrollTopElm class="col gap-2 flex-1 overflow-auto">
        <h6 *ngIf="questionReview">
          <span *ngIf="!questionReview.isCorrectAnswer" class="text-red-0">Chưa phải đáp án đúng!</span
          ><span *ngIf="questionReview.isCorrectAnswer" class="text-darkEmerald">Đáp án chính xác!</span>
        </h6>
        <!-- <p><span [innerHTML]="question.content | safeHtml"></span></p> -->
        <div class="mt-2 flex flex-col gap-1"></div>
        <div class="col flex-1">
          <!-- <span #htmlRoot [innerHTML]="question.content | safeHtml"></span> -->
          <kyonsvn-exercise-content
            [question]="question"
            [progress]="progress"
            [submission]="submission"
            (submissionEvent)="updateSubmission($event)"
            class="flex md:flex-1 md:max-h-full md:overflow-y-auto"
            [isActive]="true"
          ></kyonsvn-exercise-content>
        </div>
        <div *ngIf="questionReview" class="col gap-2">
          <h5>Lời giải</h5>
          <!-- <span [innerHTML]="questionReview.content | safeHtml"></span> -->
          <!-- <span [innerHTML]="questionReview.html | safeHtml"></span> -->
          <!-- <span [innerHTML]="questionReview.explanation | safeHtml"></span> -->
          <kyonsvn-latex [inputString]="questionReview.explanation"></kyonsvn-latex>
        </div>
      </div>
      <div class="justify-between hidden md:flex flex-col gap-3">
        <div class="hidden md:flex flex-1 gap-2">
          <strong class="uppercase">Phím tắt:</strong>
          <span>Bấm 1, 2, 3, 4 chọn đáp án, bấm space để làm tiếp</span>
        </div>
        <div class="flex gap-4 items-center justify-between w-full">
          <!-- <button (click)="scrollLeft()" [disabled]="currentTestIndex === 0" class="btn primary">Câu trước</button>
          <div class="flex-1 flex gap-1 w-full overflow-hidden">
            <button class="btn-icon primary outlined" (click)="previousPage()"><i class="icon-ChevronLeft"></i></button>
            <div #scrollElm class="w-[calc(100%_-_92px)] overflow-x-auto flex gap-1 hide-scrollbar">
              <button
                class="btn-icon primary outlined"
                *ngFor="let question of testContent.questions; index as i"
                [ngClass]="{
                  'fill-blue': i === currentTestIndex,
                  'outline-blue': i !== currentTestIndex,
                  'fill-black': testSubmission.hasAnswer(question.id)
                }"
                (click)="currentTestIndex = i"
              >
                {{ i + 1 }}
              </button>
            </div>
            <button class="btn-icon outlined orange" (click)="nextPage()"><i class="icon-ChevronRight"></i></button>
          </div>
          <button *ngIf="currentTestIndex !== testContent.questions.length - 1" (click)="scrollRight()" class="btn primary">
            Câu kế tiếp
          </button> -->
          <button [disabled]="progress.value === 0" [routerLink]="['review']" class="btn secondary">
            Xem lại các dạng bài
          </button>
          <button *ngIf="!questionReview" [disabled]="isSubmitting" (click)="testComplete()" class="btn primary">
            Nộp bài
          </button>
          <button *ngIf="questionReview" [disabled]="isSubmitting" (click)="nextQuestion()" class="btn primary">
            Câu kế tiếp
          </button>
        </div>
      </div>
      <div *ngIf="isSubmitting" class="absolute top-0 left-0 w-full h-full z-50"></div>
    </div>
    <!-- <div class="ads-md">
      <span class="text-white">Muốn luyện tập với vô hạn câu hỏi được tạo ra bởi Trí tuệ nhân tạo (AI)?</span>
      <button class="btn btn-large" (click)="showWaitlistPopup = true">Đăng ký Danh sách chờ của Kyons!</button>
    </div> -->
    <div class="md:hidden w-full flex items-center justify-between gap-2">
      <div class="flex gap-2 items-center w-full justify-between">
        <button [disabled]="progress.value === 0" [routerLink]="['review']" class="btn-icon link secondary">
          <i class="icon-Preview"></i>
        </button>
        <!-- <button (click)="scrollLeft()" [disabled]="currentTestIndex === 0" class="btn-icon primary">
          <i class="icon-ChevronLeft"></i>
        </button> -->
        <!-- <a
          class="btn-icon primary outlined order-1 md:order-1"
          href="https://forms.gle/b36mTdMWNRY1qkZh7"
          target="_blank"
          #tooltip="matTooltip"
          matTooltip="Nội dung “có vấn đề”? Thông báo cho Kyons liền tay!"
          matTooltipPosition="above"
          matTooltipHideDelay="5000"
          matTooltipClass="above"
          aria-label="Button that displays a tooltip that hides when scrolled out of the container"
          ><i class="icon-Error"></i
        ></a> -->
        <!-- <div #scrollXsElm class="max-w-[calc(100%_-_92px)] overflow-x-auto flex gap-[4px] hide-scrollbar">
          <button
            class="btn-icon primary outlined"
            *ngFor="let question of testContent.questions; index as i"
            [ngClass]="{
              'fill-blue': i === currentTestIndex,
              'outline-blue': i !== currentTestIndex,
              'fill-black': testSubmission.hasAnswer(question.id)
            }"
            (click)="currentTestIndex = i"
          >
            {{ i + 1 }}
          </button>
        </div> -->
        <button [disabled]="isSubmitting" *ngIf="questionReview" (click)="nextQuestion()" class="btn-icon primary">
          <i class="icon-ChevronRight"></i>
        </button>
        <button
          [disabled]="isSubmitting"
          *ngIf="!questionReview"
          (click)="testComplete()"
          class="btn-icon primary outlined"
        >
          <i class="icon-ChevronRight"></i>
        </button>
        <!-- <button
          *ngIf="currentTestIndex !== testContent.questions.length - 1"
          (click)="scrollRight()"
          [disabled]="currentTestIndex === testContent.questions.length - 1"
          class="btn-icon primary"
        >
          <i class="icon-ChevronRight"></i>
        </button> -->
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="showIncomplete"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[700px] items-center">
    <img src="/assets/images/warning.webp" alt="" />
    <div class="col w-full gap-6">
      <h5>Bạn chưa chọn đáp án!</h5>
      <button (click)="showIncomplete = false" class="btn primary w-full">Để mình làm tiếp</button>
    </div>
  </div>
</div>
<div
  *ngIf="shouldViewLesson"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[700px] items-center">
    <img src="/assets/images/warning.webp" alt="" />
    <div class="col w-full gap-6">
      <h5>Úi úi, bạn sai 2 câu liên tiếp rồi đó!</h5>
      <p>Nè nè, coi lại bài học chỗ này đi, bạn có muốn xem lại bài học không?</p>
      <button (click)="shouldViewLesson = false; showLesson = true" class="btn primary w-full">Xem bài học</button>
      <button (click)="shouldViewLesson = false" class="btn primary outlined w-full">Khum, tui làm tiếp!</button>
    </div>
  </div>
</div>
<div
  *ngIf="shouldChat"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[700px] items-center">
    <img src="/assets/images/ChatBot.svg" alt="" />
    <div class="col w-full gap-6">
      <h5>Úi úi, bạn lại sai tiếp rồi, hãy để Kyo trợ giúp bạn nhé?</h5>
      <p>Hình như bạn đang gặp khó khăn ở bài học này rồi, kết nối với Kyo ngay để hỗ trợ bạn phần này nhé!</p>
      <button (click)="shouldChat = false; showChatbot = true" class="btn primary w-full">Mở Kyo</button>
      <button (click)="shouldChat = false" class="btn primary outlined w-full">Khum, tui làm tiếp!</button>
    </div>
  </div>
</div>
<div
  [ngClass]="{ flex: showLesson, hidden: !showLesson }"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center z-20"
>
  @if(showLesson){
  <student-view-lesson trackingType="on_lesson_view" [lessonId]="lessonId"></student-view-lesson>}
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[800px] items-center">
    <div class="col w-full gap-6">
      <div class="flex justify-between">
        <h3>Nội dung bài học</h3>
        <button class="btn-icon primary outlined" (click)="showLesson = false"><i class="icon-XClose"></i></button>
      </div>
      @if(content===''){
      <div class="">Bài học chưa cập nhật nội dung</div>
      } @else {
      <div class="relative w-full bg-[#313131]">
        <ng-lottie
          [options]="options"
          width="100px"
          height="100px"
          class="absolute top-[calc(50%_-_50px)] left-[calc(50%_-_50px)]"
        ></ng-lottie>
        <embed
          [src]="content | safeResourceUrl"
          width="100%"
          height="100%"
          class="w-full h-[80vh] min-h-[80vh] relative"
        />
      </div>
      }

      <!-- <embed [src]="content" width="100%" height="100%" style="height: 80vh" /> -->
      <button (click)="showLesson = false" class="btn primary w-full">Đã hiểu</button>
    </div>
  </div>
</div>
<div
  *ngIf="showChatbot"
  class="chatbot-modal absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[700px] items-center">
    <div class="chatbox">
      <div class="head">
        <h3 class="whitespace-nowrap hidden-default">Chat: KYONS</h3>
        <div class="relative z-10 w-full h-[40px]">
          <div class="absolute right-6 flex gap-4 w-full left-0 justify-end items-center">
            <div class="w-[170px] h-[40px] relative">
              <img [src]="'/assets/images/' + themeService.getTheme() + '/Mana.svg'" alt="" class="h-[40px]" />
              <div class="battery-bg" [style.width]="manaWidth + 'px'"></div>
              <div class="battery-number">
                {{ batteryLife }}
              </div>
            </div>
          </div>
        </div>
        <div class="w-4"></div>
        <button class="btn-icon primary outlined" (click)="showChatbot = false"><i class="icon-XClose"></i></button>
      </div>
      <div class="chat-divider"></div>
      <messages
        [messages]="messages"
        [chatId]="lessonId"
        [userId]="currentUser().uuid"
        [isThinking]="isThinking"
        (isWritingEvent)="updateThinking($event)"
      />
      <div class="options">
        <div class="option" (click)="option1()">
          <span>Phân tích câu hỏi</span>
          <div class="w-8 h-8 p-2 items-center justify-center bg-white flex rounded-lg">
            <i class="icon-ArrowForward text-blueGray-400 text-2xl"></i>
          </div>
        </div>
        <div class="option" *ngIf="questionReview" (click)="option2()">
          <span>Giải thích đáp án</span>
          <div class="w-8 h-8 p-2 items-center justify-center bg-white flex rounded-lg">
            <i class="icon-ArrowForward text-blueGray-400 text-2xl"></i>
          </div>
        </div>
      </div>
      <div class="chat-divider"></div>
      <chatbox (sendMessage)="sendMessage($event)" [isThinking]="isThinking" />
    </div>
  </div>
</div>
<div
  *ngIf="showComplete"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[700px] items-center">
    <div class="col w-full gap-6 justify-center items-stretch">
      <img class="w-[150px] self-center" src="/assets/images/lesson-success.webp" alt="" />
      <h5 class="w-full">Xin chúc mừng, bạn vừa hoàn thành xuất sắc {{ lesson.name }} rồi đó!</h5>
      <p>Con đường để hoàn thành mục tiêu học tập cũng đang đến gần hơn rồi, tiếp tục ôn tập thôi nào bạn ơi!</p>
      <button [routerLink]="paths.learningPath.path" class="btn primary w-full">Quay lại Lộ trình học</button>
      <button [routerLink]="['review']" class="btn primary outlined w-full">Xem lại các bài tập đã làm</button>
    </div>
  </div>
</div>
<div
  *ngIf="isOutOfSubscription"
  class="absolute top-0 left-0 w-full h-screen bg-black bg-opacity-80 flex items-center justify-center"
>
  <div class="col rounded-2xl bg-white gap-6 p-6 w-full md:w-[700px] items-center">
    <div class="col w-full gap-6 justify-center items-stretch">
      <img class="w-[150px] self-center" src="/assets/images/easymodecompleted.webp" alt="" />
      <h5 class="w-full">Rất tiếc! Bạn đã làm hết các câu dễ rồi</h5>
      <p>
        Bạn vẫn có thể học tiếp các bài học tiếp theo, chỉ là sẽ bị hạn chế các câu hỏi nâng cao hơn thôi. Để mở khóa
        tính năng này, hãy mua gói “Plus” của Kyons nhé!
      </p>
      <button [routerLink]="paths.account.path" class="btn primary w-full">Mua gói Plus</button>
      <button [routerLink]="paths.learningPath.path" class="btn primary outlined w-full">Trở về Lộ trình học</button>
    </div>
  </div>
</div>
