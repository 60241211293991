import { Injectable, signal } from '@angular/core';
import { SkinType, ThemeType } from '@share-utils/view';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  skinStore = signal<SkinType>(this.getSkin());
  themeStore = signal<ThemeType>(this.getTheme());
  constructor() {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (event) => {
        const theme = event.matches ? ThemeType.dark : ThemeType.light;
        this.setTheme(theme);
      });
  }
  setSkin(skin: string) {
    this.skinStore.set(SkinType[skin as keyof typeof SkinType]);
    window.localStorage.setItem('skin', skin);
  }
  setTheme(theme: string) {
    this.themeStore.set(ThemeType[theme as keyof typeof ThemeType]);
    window.localStorage.setItem('theme', theme);
  }
  // constructor() {
  //   this.themeStore.update(theme => {
  //     console.log('theme', theme);

  //     window.localStorage.setItem('theme', theme);
  //     return theme;
  //   });
  // }

  getSkin(): SkinType {
    const skin = window.localStorage.getItem('skin') ?? 'default';
    if (skin in SkinType) {
      return SkinType[skin as keyof typeof SkinType];
    } else {
      return SkinType.default;
    }
  }
  getTheme() {
    const theme = window.localStorage.getItem('theme');
    if (theme != null && theme in ThemeType) {
      return ThemeType[theme as keyof typeof ThemeType];
    } else {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)") ? ThemeType.dark : ThemeType.light;
      return systemTheme;
    }
  }
}
