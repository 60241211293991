<div class="grid grid-rows-1 w-full h-full overflow-auto chatbot">
  <student-chatbot-sidebar
    [isSmMenuHide]="isSmMenuHide"
    class="contents"
    [ngClass]="{ 'xl:contents': isCompact }"
    [isCompact]="isCompact"
  />
  <div
    class="w-full h-full gap-3 overflow-hidden flex justify-center p-4"
    [ngClass]="{ 'md:px-4 xl:p-8 pb-12 md:pb-8': !isCompact, 'pt-0': isCompact }"
  >
    <div class="chatbox gap-2">
      @if(!isCompact){
      <div class="flex justify-end xl:hidden">
        <button class="btn-icon link" (click)="toggleMenu()">
          <i class="icon-History"></i>
        </button>
      </div>
      }
      <div class="xl:flex" [ngClass]="{ hidden: !isCompact }"></div>
      @if(!isLoadingMessages){
      <!--  -->
      @if(!isCompact){<ng-flutter
        *ngIf="flutterAppLoaded"
        src="/find-me-game/main.dart.js"
        assetBase="/find-me-game/"
        (appLoaded)="onFlutterAppLoaded($event)"
        class="find-me-game"
        [ngClass]="{ hidden: !isGaming }"
      ></ng-flutter
      >}
      <!--  -->
      <messages
        [messages]="messages"
        [isThinking]="isThinking"
        [isEditing]="isEditing"
        (startGame)="sendMessage('/play find-me')"
        (isWritingEvent)="updateThinking($event)"
        [userId]="currentUser().uuid"
        [chatId]="chatId"
        [fileService]="fileService"
        (likeEvent)="onLikeMessage($event)"
        (undoEvent)="onUndoMessage($event)"
        (removeEvent)="onRemoveMessage($event)"
        [reviewMessage]="reviewMessage"
        [interactiveModels]="selectedModels"
        (editEvent)="onEditMessageOfMessageComponent($event)"
        [noManaError]="noManaError"
      />
      <div class="chat-divider"></div>
      <chatbox
        #chatbox
        (sendMessage)="sendMessage($event)"
        (sendMathMessage)="sendMathMessage($event)"
        [isThinking]="isThinking"
        [isEditing]="isEditing"
        (endGame)="end()"
        [isGaming]="isGaming"
        (selectFile)="selectFile($event)"
        (selectImage)="selectImage($event)"
        (removeImage)="removeImage()"
        (selectFileFromStorage)="selectFileFromStorage($event)"
        [fileService]="fileService"
        [userId]="currentUser().uuid"
        [hasFileFeature]="true"
        (transcript)="transcript($event)"
        (sendReviewMessage)="sendReviewMessage($event)"
        [modelsList]="modelsList"
        (modelsChangeEvent)="onModelsChange($event)"
        (removeTextEvent)="onRemoveText()"
      />
      } @else if(chatId){
      <!-- <div class="place-self-center flex items-center">
          <ng-lottie [options]="options" width="200px" height="200px"></ng-lottie>
        </div> -->
      <div class="body">
        <div class="message right">
          <div class="content w-full">
            <div class="box w-full shine h-[48px]"></div>
            <div class="box w-full shine h-[48px]"></div>
          </div>
        </div>
        <div class="message left">
          <div class="avatar"><img src="assets/images/KyoAI.svg" alt="" /></div>
          <div class="content w-full">
            <div class="box w-full shine h-[48px]"></div>
            <div class="box w-full shine h-[48px]"></div>
            <div class="box w-full shine h-[48px]"></div>
          </div>
        </div>
        <div class="message right">
          <div class="content w-full">
            <div class="box w-full shine h-[48px]"></div>
          </div>
        </div>
        <div class="message left">
          <div class="avatar"><img src="assets/images/KyoAI.svg" alt="" /></div>
          <div class="content w-full">
            <div class="box w-full shine h-[120px]"></div>
          </div>
        </div>
      </div>
      }
    </div>
    @if(!isCompact){
    <div class="ai-warning">
      <span>AI có thể đưa ra thông tin không chính xác. Hãy luôn kiểm tra lại.</span
      ><a href="https://support.google.com/gemini?p=privacy_notice">Xem chính sách và quyền sử dụng</a>
    </div>
    }
  </div>
  <!-- <div class="xl:flex hidden flex-col flex-1 items-end">
    <h3>{{ theme === 'universe' ? 'Countdown' : 'Đếm ngược' }}</h3>
      <div class="flex gap-1 w-full items-center justify-center text-white">
        <div
          class="flex flex-col w-[calc((100%_-_36px)_/_4)] max-w-[70px] bg-blueGray-800 py-[14px] rounded-lg text-center"
        >
          <strong class="text-3xl">{{ day }}</strong>
          {{ theme === 'universe' ? (parseInt(day) < 2 ? 'day' : 'days') : 'ngày' }}
        </div>
        <span class="text-3xl text-primaryBlue">:</span>
        <div
          class="flex flex-col w-[calc((100%_-_36px)_/_4)] max-w-[70px] bg-blueGray-800 py-[14px] rounded-lg text-center"
        >
          <strong class="text-3xl">{{ hour }}</strong>
          {{ theme === 'universe' ? (parseInt(hour) < 2 ? 'hour' : 'hours') : 'giờ' }}
        </div>
        <span class="text-3xl text-primaryBlue">:</span>
        <div
          class="flex flex-col w-[calc((100%_-_36px)_/_4)] max-w-[70px] bg-blueGray-800 py-[14px] rounded-lg text-center"
        >
          <strong class="text-3xl">{{ minute }}</strong>
          {{ theme === 'universe' ? (parseInt(minute) < 2 ? 'minute' : 'minutes') : 'phút' }}
        </div>
        <span class="text-3xl text-primaryBlue">:</span>
        <div
          class="flex flex-col w-[calc((100%_-_36px)_/_4)] max-w-[70px] bg-blueGray-800 py-[14px] rounded-lg text-center"
        >
          <strong class="text-3xl">{{ second }}</strong>
          {{ theme === 'universe' ? (parseInt(second) < 2 ? 'second' : 'seconds') : 'giây' }}
        </div>
      </div>
  </div> -->
</div>
