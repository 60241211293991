import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { environment } from '@environments';
import { catchError, firstValueFrom } from 'rxjs';
import { serverApi } from '../auth/interceptor';
import { DBHelper } from '../helper/helper';

export const CURRENT_USER = 'current' + environment.name + 'User';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  currentUser: WritableSignal<{
    mobile_number: string; user_name: string, uuid: string, email: string, user_type: string, first_name: string, last_name: string,
    providers: [],
    provider: 'email' | 'google_oauth2' | 'facebook'
  }> = signal({
    user_name: '',
    uuid: '',
    email: '',
    user_type: '',
    first_name: '',
    last_name: '',
    mobile_number: '',
    providers: [],
    provider: 'email'
  });
  http = inject(HttpClient);

  async updateCurrentUser(uuid?: string, provider?: string) {
    if (!uuid) {
      this.currentUser.set(JSON.parse(window.localStorage.getItem(CURRENT_USER) ?? '{}'));
      return;
    }
    const params = new HttpParams().set('id', uuid);
    const promise = new Promise((resolve, reject) => {
      firstValueFrom(
        this.http
          .get(`${serverApi()}/auth/get_user`, {
            params: params,
          })
          .pipe(catchError(DBHelper.handleError('GET get_user', null)))
      ).then(
        (res: any) => {
          // Success
          res['uuid'] = uuid;
          if (provider) {
            res['provider'] = provider;
          }
          window.localStorage.setItem(CURRENT_USER, JSON.stringify(res));
          this.currentUser.set(res);
          resolve(true);
        },
        msg => {
          // Error
          reject(msg);
        }
      );
    });
    return promise;
  }

  getUserId(): string {
    const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER) ?? '{}');
    return currentUser.uuid;
  }
  // getEmail() {
  //   const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER) ?? '{}');
  //   return currentUser != null ? currentUser.email : '';
  // }
  // getUserType() {
  //   const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER) ?? '{}');
  //   return currentUser.study_type;
  // }

  // getUserId() {
  //   const currentUser = JSON.parse(window.localStorage.getItem(CURRENT_USER) ?? '{}');
  //   return currentUser.uuid;
  // }

  removeCurrentUser() {
    window.localStorage.removeItem(CURRENT_USER);
    this.currentUser.set({
      user_name: '',
      uuid: '',
      email: '',
      user_type: '',
      first_name: '',
      last_name: '',
      mobile_number: '',
      providers: [],
      provider: 'email'
    });
  }

  // getUserInfo(userId: string) {
  //   const params = new HttpParams().set('id', userId);
  //   return this.http.get(`${serverApi()}/auth/get_user`, { params: params }).pipe(
  //     catchError(DBHelper.handleError('GET get_user', null)),
  //     map((res: any) => {
  //       return User.fromJson(res);
  //     })
  //   );
  // }

  setForceCompleteTutorial(forceComplete: boolean = true) {
    window.localStorage.setItem('forceCompleteTutorial', forceComplete.toString());
  }

  updateProfile({ firstName, lastName, email, phone, birthdate }: { firstName: string, lastName: string, email: string, phone: string, birthdate: string }) {
    // PUT /api/v2/users/me
    // Example Value
    // {
    //   "address": "123 Nguyễn Du",
    //   "dob": "2008-07-20",
    //   "first_name": "Mac",
    //   "grade": "10",
    //   "last_name": "Khanh",
    //   "mobile_number": "0909090909",
    //   "school": "THPT Nguyễn Viết Xuân"
    // }
    const params = {
      dob: birthdate,
      first_name: firstName,
      last_name: lastName,
      mobile_number: phone,
    }

    return this.http.put(`${serverApi()}/api/v2/users/me`, params);
  }

  updatePassword(value: { current_password: string, password: string }) {
    return this.http.put(`${serverApi()}/api/v2/users/me/password`, value);
  }

}
