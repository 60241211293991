<div class="w-full flex px-4 py-2 xl:py-2 xl:px-10 items-center gap-2 bg-white shadow-1 relative z-10">
  <div class="xl:hidden">
    <button class="btn-icon link xl:hidden" (click)="isShowMobileMenu = !isShowMobileMenu">
      <i class="icon-MenuHamburger"></i>
    </button>
  </div>
  <div class="flex-1"></div>
  <top-right-menu />
</div>
<div class="left-menu" [ngClass]="{ showMobileMenu: isShowMobileMenu, isCollapsed }" (click)="isShowMobileMenu = false">
  <div id="collapseMenuBtn" class="absolute hidden xl:flex">
    <button class="btn-icon outlined white circle small shadow-1 !bg-primaryBlue-500" (click)="collapse()">
      <i [ngClass]="{ 'icon-ChevronRight': isCollapsed, 'icon-ChevronLeft': !isCollapsed }"></i>
    </button>
  </div>
  <div
    class="absolute h-full top-0 left-0 bg-primaryBlue-500 text-white p-4 col xl:py-8 gap-6 lx:gap-10 overflow-hidden z-10"
    [ngClass]="{ ' w-[240px]': !isCollapsed, 'w-[80px]': isCollapsed }"
    (click)="$event.stopPropagation()"
  >
    <div class="h-[48px] relative w-full">
      <a href="/" [ngClass]="{ 'opacity-0': !isCollapsed }" class="absolute w-[48px] h-[48px] hidden xl:block"
        ><img src="/assets/icons/left-menu-logo-icon.svg" alt="Logo" class="w-[48px]"
      /></a>
      <div
        class="flex gap-2 items-center absolute w-full top-0 left-0 h-[48px]"
        [ngClass]="{ 'opacity-0 absolute': isCollapsed }"
      >
        <div class="xl:hidden">
          <button class="btn-icon primary link" (click)="isShowMobileMenu = false">
            <i class="icon-XClose"></i>
          </button>
        </div>
        <a href="/" class="w-full"
          ><img src="/assets/images/logo-horizontal-light.svg" alt="Logo" class="h-[48px] max-w-[unset]"
        /></a>
      </div>
    </div>
    <ul class="col gap-4" (click)="close()">
      <li>
        <a class="menu-item" [routerLink]="'/'" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
          <img src="/assets/icons/Home.svg" alt="Home menu" />
          <span class="menu-text">Trang chủ</span>
        </a>
      </li>
      <li>
        <a class="menu-item" [routerLink]="paths.chatbot.path" routerLinkActive="active" target="_blank">
          <img src="/assets/images/FAQ.svg" alt="" />
          <span class="menu-text">Chat với Kyo</span>
        </a>
      </li>
      <!-- <li>
        <a class="menu-item" [routerLink]="paths.chatbot.path" routerLinkActive="active" target="_blank">
          <img src="/assets/images/FAQ.svg" alt="" />
          <span class="menu-text">Chat với Kyo</span>
        </a>
      </li> -->
      <li>
        <a class="menu-item" [routerLink]="paths.task.path" routerLinkActive="active">
          <img src="/assets/icons/Mission.svg" alt="" />
          <span class="menu-text">Nhiệm vụ</span>
        </a>
      </li>
    </ul>
  </div>
</div>
