import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'skin-universe',
  standalone: true,
  template: '',
  styleUrls: ['../scss/themes/skin/universe/universe.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SkinUniverseComponent {
}