<div class="side-bar" [ngClass]="{ 'show-sm': !isSmMenuHide(), 'p-0': isCompact, 'xl:p-8 md:p-6': !isCompact }">
  <div class="sidenav-with-scrollable-list">
    <div class="history">
      <div class="flex justify-between items-start" [ngClass]="{ 'xl:hidden': !isCompact }">
        <span class="h7">{{ theme === 'universe' ? 'History' : 'Trò chuyện gần đây' }}</span>
        <button class="btn-icon link" (click)="toggleMenu()">
          <i class="icon-XClose"></i>
        </button>
      </div>
      <div class="flex gap-4">
        <!-- <button class="btn-icon" (click)="toggleMenu()">
          <i class="icon-Sidebar"></i>
        </button> -->
        <!-- <span class="h7 hidden" [ngClass]="{ 'xl:block': !isCompact }">{{
          theme === 'universe' ? 'History' : 'Trò chuyện gần đây'
        }}</span> -->
        <div class="prefix-icon flex-1">
          <i class="icon-Search absolute pointer-events-none"></i>
          <input
            type="text"
            name="searchChat"
            placeholder="Tìm kiếm..."
            [(ngModel)]="searchChat"
            (ngModelChange)="onSearchChat()"
            class="w-full"
          />
        </div>
        <button
          *ngIf="chatId"
          class="btn-icon link"
          [routerLink]="paths.chatbot.path"
          [disabled]="chatId === ''"
          routerLinkActive="disabled"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <i class="icon-PlusCircle"></i>
        </button>
      </div>
    </div>
    <ul class="left-list">
      @for (item of groupNames; track $index) { @if(chats[item]&&chats[item]['data'].length>0){
      <li>
        <span class="note">{{ chats[item]['label'] }}</span>
        <ul>
          <li *ngFor="let chat of chats[item]['data']" [ngClass]="{ active: chatId === chat.id }">
            <div
              class="side-bar-item relative"
              [ngClass]="{
                'z-20': selectedChat()?.id === chat.id,
                shine: selectedChat()?.id === chat.id && updatingChat
              }"
            >
              @if(!showEditChat()||selectedChat()?.id !== chat.id){<a
                class="chat-name"
                (click)="goToChat(chat.id)"
                [title]="chat.firstMessage"
                >{{ chat.firstMessage }}</a
              >} @else if(selectedChat()?.id === chat.id){
              <div class="flex gap-2">
                <input type="text" [(ngModel)]="chatName" (keyup.enter)="updateChatName()" class="w-full" />
                <button class="btn-icon link" (click)="updateChatName()">
                  <i class="icon-Check"></i>
                </button>
              </div>
              } @if(!showEditChat()){
              <div class="flex items-center relative opacity-0">
                <button
                  [matMenuTriggerFor]="chatMenu"
                  aria-label="icon-button with menu"
                  (click)="selectedChat.set(chat); selectedChatGroup.set(item)"
                  [disabled]="updatingChat"
                >
                  <i class="icon-DotsHorizontal"></i>
                </button>
              </div>
              }
            </div>
          </li>
        </ul>
      </li>
      } }
    </ul>
    <!-- <div class="flex flex-col self-end">
      <ul class="left-list">
        <li>
          <a class="btn primary outlined rounded-full" [routerLink]="paths.fileManager.path">
            <span></span><i class="icon-Folder"></i><span>Quản lý file</span></a
          >
        </li>
      </ul>
    </div> -->
  </div>
</div>
<mat-menu #chatMenu yPosition="below" xPosition="before"
  ><button mat-menu-item (click)="chatName = selectedChat()?.firstMessage ?? ''; showEditChat.set(true)">
    <i class="icon-Edit"></i>
    <span>Sửa tên</span>
  </button>
  <button mat-menu-item (click)="showConfirmDeleteChat.set(true)">
    <i class="icon-Trash"></i>
    <span>Xóa</span>
  </button>
</mat-menu>
<div
  class="fixed top-0 left-0 right-0 bottom-0 z-10"
  (click)="showEditChat.set(false)"
  [ngClass]="{ hidden: !showEditChat() }"
></div>
<div
  *ngIf="showConfirmDeleteChat()"
  class="fixed top-0 left-0 w-full h-screen overflow-hidden flex items-center justify-center p-4 z-20 bg-black bg-opacity-50"
>
  <div class="w-full max-w-[443px] flex flex-col gap-6 bg-white rounded-lg p-6">
    <h5 class="text-center">Bạn chắc chắn muốn xóa đoạn trò chuyện này?</h5>
    <div class="flex gap-4 justify-center">
      <button class="btn primary outlined" (click)="showConfirmDeleteChat.set(false)">Hủy</button>
      <button class="btn primary" (click)="deleteChat()">Chắc chắn</button>
    </div>
  </div>
</div>
